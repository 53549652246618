import { Component, Vue } from 'vue-property-decorator';
import Incomming from '@/views/ReportStatistics/ProductionStatistics/Incomming/Incomming.vue';
import Outgoing from '@/views/ReportStatistics/ProductionStatistics/Outgoing/Outgoing.vue';

@Component<ProductionStatistics>({
  components: {
    Outgoing,
    Incomming,
  },
})
export default class ProductionStatistics extends Vue {
  // #region @VModel
  // #endregion

  // #region @PropSyncs
  // #endregion

  // #region @Props
  // #endregion

  // #region @Refs
  // #endregion

  // #region Class properties

  public $pageTitle = 'Output TCers';

  // #endregion

  // #region Lifecycle Hooks / Init
  // #endregion

  // #region Class methods

  protected isProductionStatistics(type: string) {
    return this.$route.path.includes(type);
  }

  // #endregion

  // #region Async methods
  // #endregion

  // #region Getters & Setters
  // #endregion

  // #region @Watchers
  // #endregion
}

// #region @Enums
// #endregion

// #region @Types
// #endregion

// #region @Interfaces
// #endregion
