var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        {
          attrs: { "slider-color": "secondary" },
          model: {
            value: _vm.activeOrganization,
            callback: function($$v) {
              _vm.activeOrganization = $$v
            },
            expression: "activeOrganization"
          }
        },
        [
          _vm._l(_vm.organizations, function(organization, index) {
            return [
              _c(
                "v-tab",
                {
                  key: "planning-tab-" + index,
                  staticClass: "tab__filled",
                  class: { active: _vm.activeOrganization === organization.id },
                  attrs: {
                    href: "#" + organization.id,
                    disabled: _vm.isLoading
                  },
                  on: {
                    click: function($event) {
                      return _vm.activeOrganizationChanged(organization.id)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(organization.name) + " ")]
              )
            ]
          }),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.activeOrganization,
                callback: function($$v) {
                  _vm.activeOrganization = $$v
                },
                expression: "activeOrganization"
              }
            },
            [
              _vm._l(_vm.organizations, function(organization) {
                return [
                  _c(
                    "v-tab-item",
                    {
                      key: "termijn-tab-content-" + organization.id,
                      attrs: {
                        lazy: true,
                        transition: false,
                        "reverse-transition": false,
                        value: organization.id
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "elementPanel elementPanel--spaced fill-height"
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: { wrap: "", "justify-space-between": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "info-icon",
                                  attrs: { shrink: "" }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openReadMoreDialog(
                                                            "mutatie"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v("info_outline")]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("klik voor meer info")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", "align-end": "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "", "mb-2": "" } },
                                [
                                  _c("PeriodFilter", {
                                    attrs: {
                                      to: _vm.filters.to,
                                      from: _vm.filters.from,
                                      clearable: false
                                    },
                                    on: { change: _vm.applyDateFilters }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                                [
                                  _c("ReportDepartmentFilter", {
                                    attrs: {
                                      label: "Afdelingen",
                                      placeholder: "",
                                      cssClass: "with-border",
                                      dmz: _vm.activeOrganization,
                                      canSelectAll: true
                                    },
                                    model: {
                                      value: _vm.filters.departments,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "departments",
                                          $$v
                                        )
                                      },
                                      expression: "filters.departments"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-xs-right",
                                  attrs: { sm12: "" }
                                },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        color: "primary",
                                        small: "true",
                                        outline: "true"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.applyFilters()
                                        }
                                      }
                                    },
                                    [_vm._v(" Pas filters toe ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", {
                                staticClass: "separator",
                                attrs: { xs12: "" }
                              })
                            ],
                            1
                          ),
                          _vm.isLoading
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("LoaderCard", {
                                        attrs: {
                                          flat: "",
                                          type: "spinner--center"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isLoading
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "elementPanel elementPanel--spaced"
                                  },
                                  [
                                    _vm.reportTypeResults[
                                      _vm.activeOrganization
                                    ] &&
                                    _vm.reportTypeResults[
                                      _vm.activeOrganization
                                    ].length
                                      ? _c("StatisticsView", {
                                          attrs: {
                                            items:
                                              _vm.reportTypeResults[
                                                _vm.activeOrganization
                                              ],
                                            columns: _vm.reportTypeColumns,
                                            options: _vm.statisticsOptions
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "elementPanel elementPanel--spaced"
                                  },
                                  [
                                    _vm.opnameVariantsResults[
                                      _vm.activeOrganization
                                    ] &&
                                    _vm.opnameVariantsResults[
                                      _vm.activeOrganization
                                    ].length
                                      ? _c("StatisticsView", {
                                          attrs: {
                                            items:
                                              _vm.opnameVariantsResults[
                                                _vm.activeOrganization
                                              ],
                                            columns: _vm.opnameVariantColumns,
                                            options: _vm.statisticsOptions
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                !_vm.reportTypeResults[
                                  _vm.activeOrganization
                                ] ||
                                !_vm.reportTypeResults[_vm.activeOrganization]
                                  .length
                                  ? _c("div", { staticClass: "mt-3" }, [
                                      _c("span", [
                                        _vm._v(
                                          'Druk op de knop "Pas filters toe" om de resultaten te bekijken.'
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  )
                ]
              })
            ],
            2
          )
        ],
        2
      ),
      _vm.isReadingMoreInfo
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isReadingMoreInfo,
                callback: function($$v) {
                  _vm.isReadingMoreInfo = $$v
                },
                expression: "isReadingMoreInfo"
              }
            },
            [
              _c("MoreInfoDialog", {
                attrs: {
                  title: _vm.activeMoreInfo.title,
                  description: _vm.activeMoreInfo.description
                },
                model: {
                  value: _vm.isReadingMoreInfo,
                  callback: function($$v) {
                    _vm.isReadingMoreInfo = $$v
                  },
                  expression: "isReadingMoreInfo"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }