import { ReportType } from '@/models/ReportType';
import { Rpc } from '@/models/Rpc';
import { Component, Watch } from 'vue-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { User } from '@/models/User';
import { DateTime } from 'luxon';
import { StatisticsColumn, StatisticsOptions } from '@/components/week-statistics-view/WeekStatisticsView';
import { ReportStatusMap, excludePlanningStatuses } from '@/support/ReportStatus';
import { Department } from '@/models/Department';
import { Organization } from '@/models/Organization';
import { cloneDeep } from 'lodash';
import { DateFormatter } from '@/support/DateFormatter';
import { OutputTCInfo } from '@/support/Info';
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';
import AbstractMoreInfoDialog from '@/components/dialog/more-info-dialog/AbstractMoreInfoDialog';
import ReportTypeFilter from '@/components/filters/report-type-filter/ReportTypeFilter.vue';
import { downloadExcel } from '@/support/Client';

@Component<Outgoing>({
  components: {
    ReportTypeFilter,
  },
})
export default class Outgoing extends AbstractMoreInfoDialog {
  // #region @VModel
  // #endregion

  // #region @PropSyncs
  // #endregion

  // #region @Props
  // #endregion

  // #region @Refs
  // #endregion

  // #region Class properties

  public $pageTitle = 'Output TCers';

  protected isLoading = false;

  protected filters: OutgoingFilters = {
    report_types: [],
    departments: [],
    statusses: [],
    breakdown: 'reports',
    workflows: [],
    opname_variants: [],
    management_info_classes: [],
    experts: [],
    date: '',
  };

  protected savedFilters: {[id: string]: OutgoingFilters} = {};

  protected users: {[id: string]: User[]} = {};

  protected moreInfo: MoreInfo[] = OutputTCInfo;

  protected isDownloadingExcel = false;

  // columns
  protected columns: StatisticsColumn[] = [];

  protected statisticsOptions: StatisticsOptions = {
    totalColumnName: 'reports_total',
  };

  // dates
  protected date = new DateFormatter();

  // status
  protected statusses: ReportStatusMap[] = excludePlanningStatuses();

  // departments
  protected departments: Department[] = [];

  // types
  protected reportTypes: ReportType[] = [];

  // Types
  protected breakdowns: any = [
    {
      name: 'Dossiers',
      value: 'reports',
    },
    {
      name: 'Schades',
      value: 'damages',
    },
  ];

  // Organizations
  protected organizations: Organization[] | null = null;

  protected selectedOrganization: Organization | null = null;

  protected activeOrganization = '';

  protected selectAllStatus = false;

  // #endregion

  // #region Lifecycle Hooks / Init

  public mounted() {
    this.date.selectedDate = DateTime.local().toFormat('yyyy-LL-dd');
    this.emitBreadcrumb();
    this.createColumns();
    this.initialize();
  }

  protected createColumns() {
    if (this.$store.state.isServiceOrganization) {
      // TODO: Productie statistieken IMG (voor veldwerk mensen) Columns
      this.columns.push({
        key: 'name',
        name: 'Naam Medewerker',
        class: 'xs4 md2 lg4',
      },
      {
        key: 'week',
        name: 'Maandag',
        class: 'xs1 text-right',
      },
      {
        key: 'week',
        name: 'Dinsdag',
        class: 'xs1 text-right',
      },
      {
        key: 'week',
        name: 'Woensdag',
        class: 'xs1 text-right',
      },
      {
        key: 'week',
        name: 'Donderdag',
        class: 'xs1 text-right',
      },
      {
        key: 'week',
        name: 'Vrijdag',
        class: 'xs1 text-right',
      },
      {
        key: 'week',
        name: 'Zaterdag',
        class: 'xs1 text-right',
      },
      {
        key: 'week',
        name: 'Zondag',
        class: 'xs1 text-right',
      },
      {
        key: 'row_total',
        name: 'Totaal',
        class: 'xs1 text-right',
      });
    } else {
      // TODO: Productie statistieken Bureau (voor de TCers) Columns
      this.columns.push({
        key: 'name',
        name: 'Naam Medewerker',
        class: 'xs4 md2 lg4',
      },
      {
        key: 'week',
        name: 'Maandag',
        class: 'xs1 text-right',
      },
      {
        key: 'week',
        name: 'Dinsdag',
        class: 'xs1 text-right',
      },
      {
        key: 'week',
        name: 'Woensdag',
        class: 'xs1 text-right',
      },
      {
        key: 'week',
        name: 'Donderdag',
        class: 'xs1 text-right',
      },
      {
        key: 'week',
        name: 'Vrijdag',
        class: 'xs1 text-right',
      },
      {
        key: 'week',
        name: 'Zaterdag',
        class: 'xs1 text-right',
      },
      {
        key: 'week',
        name: 'Zondag',
        class: 'xs1 text-right',
      },
      {
        key: 'row_total',
        name: 'Totaal',
        class: 'xs1 text-right',
      });
    }
  }

  protected async initialize() {
    await this.getOrganizations();
  }

  // #endregion

  // #region Class methods

  protected activeOrganizationChanged(id: string) {
    this.filters = this.savedFilters[id];
    this.date.selectedDate = this.filters.date;
  }

  protected async applyFilters() {
    this.isLoading = true;

    const payload = {
      signature: 'production-statistics:outgoing',
      body: this.filters,
    };

    await new Rpc()
      .dmz(this.activeOrganization)
      .rpcPost(payload, false)
      .then((response: AxiosResponse) => {
        this.$set(this.users, `${[this.activeOrganization]}`, response.data);
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected dateChanged() {
    this.filters.date = this.date.selectedDate || '';
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Output TCers' },
        ],
      });
  }

  protected navigateToUrl(url: string) {
    return this.$router.push(url);
  }

  protected async onDownloadExcelButtonClick(): Promise<void> {
    this.isDownloadingExcel = true;

    await this.downloadExcel();

    this.isDownloadingExcel = false;
  }

  protected onSelectApplyFilter(filters: OutgoingFilters) {
    this.filters = filters;
    this.applyFilters();
  }

  // #endregion

  // #region Async methods

  protected async downloadExcel(): Promise<void> {
    const payload = new URLSearchParams();

    if (this.filters.breakdown) {
      payload.append('filters[breakdown]', this.filters.breakdown);
    }

    if (this.filters.date) {
      payload.append('filters[date]', this.filters.date);
    }

    if (this.filters.departments?.length) {
      this.filters.departments.forEach((id: string, index: number) => {
        payload.append(`filters[departments][${index}]`, id);
      });
    }

    if (this.filters.experts?.length) {
      this.filters.experts.forEach((id: string, index: number) => {
        payload.append(`filters[experts][${index}]`, id);
      });
    }

    if (this.filters.management_info_classes?.length) {
      this.filters.management_info_classes.forEach((id: string, index: number) => {
        payload.append(`filters[management_info_classes][${index}]`, id);
      });
    }

    if (this.filters.opname_variants?.length) {
      this.filters.opname_variants.forEach((id: string, index: number) => {
        payload.append(`filters[opname_variants][${index}]`, id);
      });
    }

    if (this.filters.report_types?.length) {
      this.filters.report_types.forEach((id: string, index: number) => {
        payload.append(`filters[report_types][${index}]`, id);
      });
    }

    if (this.filters.statusses?.length) {
      this.filters.statusses.forEach((id: string, index: number) => {
        payload.append(`filters[statusses][${index}]`, id);
      });
    }

    if (this.filters.workflows?.length) {
      this.filters.workflows.forEach((id: string, index: number) => {
        payload.append(`filters[workflows][${index}]`, id);
      });
    }

    // payload.forEach((val, attr) => { console.log(attr, val); });

    const options = {
      url: '/dmz/output-tc-export',
      dmz:
        this.activeOrganization
          ? this.activeOrganization
          : this.$store.state.Auth?.organization?.id
            ? this.$store.state.Auth.organization.id
            : '',
    };

    await downloadExcel(options, payload);
  }

  protected async getOrganizations() {
    if (this.$store.state.isServiceOrganization) {
      this.organizations = await new Organization()
        .filter({ type: 'expert' })
        .all()
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
        });
      if (this.organizations && ! this.activeOrganization) {
        this.activeOrganization = this.organizations[0].id || '';
        this.organizations.forEach((organization) => {
          if (organization?.id) {
            this.filters.date = this.date.selectedDate || '';
            this.savedFilters[organization.id] = cloneDeep(this.filters);
          }
        });
      }
    } else {
      this.activeOrganization = this.$store.state.Auth.organization.id;
      this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
      this.filters.date = this.date.selectedDate || '';
      this.savedFilters[this.activeOrganization] = cloneDeep(this.filters);
    }
  }

  // #endregion

  // #region Getters & Setters

  protected get isDownloadExcelButtonEnabled(): boolean {
    return ! this.isDownloadingExcel
      && !! this.users[this.activeOrganization]?.length;
  }

  // #endregion

  // #region @Watchers

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }

  @Watch('selectAllStatus', { deep: true })
  public selectAllStatusChanged(to: any, from: any) {
    if (this.selectAllStatus) {
      this.$set(this.filters, 'statusses', this.statusses.map((reportStatus: ReportStatusMap) => reportStatus.key));
    } else {
      this.$set(this.filters, 'statusses', []);
    }
  }

  @Watch('filters', { deep: true })
  protected filtersChanged() {
    this.savedFilters[this.activeOrganization] = this.filters;
  }

  // #endregion
}

// #region @Enums
// #endregion

// #region @Types
// #endregion

// #region @Interfaces

interface OutgoingFilters {
  date: string;
  report_types: string[];
  statusses: string[];
  departments: string[];
  breakdown: string;
  workflows: string[];
  opname_variants: string[];
  experts: string[];
  management_info_classes: string[];
}

// #endregion
