import { Rpc } from '@/models/Rpc';
import { Component, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { DateTime } from 'luxon';
import StatisticsView from '@/components/statistics-view/StatisticsView.vue';
import { StatisticsColumn, StatisticsOptions, StatisticsItem } from '@/components/statistics-view/StatisticsView';
import { Organization } from '@/models/Organization';
import { cloneDeep } from 'lodash';
import { MutatieOWHInfo } from '@/support/Info';
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';
import AbstractMoreInfoDialog from '@/components/dialog/more-info-dialog/AbstractMoreInfoDialog';
import PeriodFilter from '@/components/filters/period-filter/PeriodFilter.vue';

@Component<Incomming>({
  components: {
    StatisticsView,
    PeriodFilter,
  },
})
export default class Incomming extends AbstractMoreInfoDialog {
  public $pageTitle = 'Mutatie OHW';

  protected isLoading = false;

  protected isLoadingFilters = true;

  protected reportTypeResults: {[id: string]: string[]} = {};

  protected opnameVariantsResults: {[id: string]: string[]} = {};

  protected moreInfo: MoreInfo[] = MutatieOWHInfo;

  protected savedFilters: {[id: string]: IncommingFilters} = {};

  protected filters: IncommingFilters = {
    from: '',
    to: '',
    departments: [],
  };

  // columns
  protected reportTypeColumns: StatisticsColumn[] = [];

  protected opnameVariantColumns: StatisticsColumn[] = [];

  protected statisticsOptions: StatisticsOptions = {
    showTotals: true,
  };

  // Organizations
  protected organizations: Organization[] | null = null;

  protected activeOrganization = '';

  public mounted() {
    this.emitBreadcrumb();
    this.createColumns();
    this.initialize();
  }

  protected async initialize() {
    this.isLoadingFilters = true;
    await this.getOrganizations();
    const lastDayOfMonth = DateTime.local().daysInMonth;
    this.filters.from = `${DateTime.local().toFormat('yyyy-MM')}-01`;
    this.filters.to = `${DateTime.local().toFormat('yyyy-MM')}-${lastDayOfMonth}`;
    this.isLoadingFilters = false;
  }

  protected createColumns() {
    this.reportTypeColumns.push({
      key: 'name',
      name: 'Rapporttype',
      class: 'xs6',
    },
    {
      key: 'total_assigned_at',
      name: 'Totaal gegund',
      class: 'xs2 text-right',
    },
    {
      key: 'total_approved_at',
      name: 'Totaal opgeleverd',
      class: 'xs2 text-right',
    },
    {
      key: 'mutatie',
      name: 'Mutatie',
      class: 'xs2 text-right',
      transform: (item: StatisticsItem) => {
        if (item.mutatie < 0) {
          return `${item.mutatie} <i aria-hidden="true" class="v-icon material-icons success--text theme--light">trending_down</i>`;
        }
        if (item.mutatie === 0) {
          return `${item.mutatie} <i aria-hidden="true" class="v-icon material-icons alert--text theme--light">trending_flat</i>`;
        }

        return `${item.mutatie} <i aria-hidden="true" class="v-icon material-icons warning--text theme--light">trending_up</i>`;
      },
    });

    this.opnameVariantColumns.push({
      key: 'name',
      name: 'Opname variant',
      class: 'xs6',
    },
    {
      key: 'total_assigned_at',
      name: 'Totaal gegund',
      class: 'xs2 text-right',
    },
    {
      key: 'total_approved_at',
      name: 'Totaal opgeleverd',
      class: 'xs2 text-right',
    },
    {
      key: 'mutatie',
      name: 'Mutatie',
      class: 'xs2 text-right',
      transform: (item: StatisticsItem) => {
        if (item.mutatie < 0) {
          return `${item.mutatie} <i aria-hidden="true" class="v-icon material-icons success--text theme--light">trending_down</i>`;
        }
        if (item.mutatie === 0) {
          return `${item.mutatie} <i aria-hidden="true" class="v-icon material-icons alert--text theme--light">trending_flat</i>`;
        }

        return `${item.mutatie} <i aria-hidden="true" class="v-icon material-icons warning--text theme--light">trending_up</i>`;
      },
    });
  }

  protected applyFilters() {
    this.fetchStatistics(this.activeOrganization);
  }

  protected async fetchStatistics(organizationId: string) {
    this.isLoading = true;

    const reportTypePayload = {
      signature: 'management-reports:ohw-mutation',
      body: this.filters,
    };

    const opnameVariantPayload = {
      signature: 'management-reports:ohw-mutation-opname-variant',
      body: this.filters,
    };

    const [reportTypeResponse, opnameVariantResponse] = await Promise.all([
      new Rpc().dmz(organizationId).rpcPost(reportTypePayload, false),
      new Rpc().dmz(organizationId).rpcPost(opnameVariantPayload, false),
    ]);

    this.$set(this.reportTypeResults, organizationId, reportTypeResponse.data);
    this.$set(this.opnameVariantsResults, organizationId, opnameVariantResponse.data);
    this.isLoading = false;
  }

  protected async getOrganizations() {
    if (this.$store.state.isServiceOrganization) {
      this.organizations = await new Organization()
        .filter({ type: 'expert' })
        .all()
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
        });
      if (this.organizations && ! this.activeOrganization) {
        this.activeOrganization = this.organizations[0].id || '';
        this.organizations.forEach((organization) => {
          if (organization?.id) {
            this.savedFilters[organization.id] = cloneDeep(this.filters);
            this.setDateFilterValues(organization.id);
          }
        });
      }
    } else {
      this.activeOrganization = this.$store.state.Auth.organization.id;
      this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
      this.savedFilters[this.activeOrganization] = cloneDeep(this.filters);
      this.setDateFilterValues(this.activeOrganization);
    }
  }

  protected setDateFilterValues(activeOrganization: string) {
    const lastDayOfMonth = DateTime.local().daysInMonth;
    this.savedFilters[activeOrganization].from = `${DateTime.local().toFormat('yyyy-MM')}-01`;
    this.savedFilters[activeOrganization].to = `${DateTime.local().toFormat('yyyy-MM')}-${lastDayOfMonth}`;
  }

  protected navigateToUrl(url: string) {
    return this.$router.push(url);
  }

  protected applyDateFilters(from: string, to: string) {
    this.filters.from = from;
    this.filters.to = to;
  }

  protected activeOrganizationChanged(id: string) {
    this.filters = this.savedFilters[id];
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Mutatie OHW' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }

  @Watch('filters', { deep: true })
  protected filtersChanged() {
    this.savedFilters[this.activeOrganization] = this.filters;
  }
}

interface IncommingFilters {
  from: string;
  to: string;
  departments: string[];
}
