var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v(
                _vm._s(
                  _vm.isProductionStatistics("incomming")
                    ? "Mutatie OHW"
                    : "Output TCers"
                )
              )
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", md12: "" } },
            [
              _vm.isProductionStatistics("incomming")
                ? _c("Incomming")
                : _vm._e(),
              _vm.isProductionStatistics("outgoing") ? _c("Outgoing") : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }