var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "tw-flex tw-grow tw-justify-end" },
        [
          _c(
            "AButton",
            {
              attrs: {
                color: "primary",
                disabled: !_vm.isDownloadExcelButtonEnabled,
                "prepend-inner-icon": "cloud_download"
              },
              on: { click: _vm.onDownloadExcelButtonClick }
            },
            [_vm._v(" Download Excel ")]
          )
        ],
        1
      ),
      !_vm.organizations
        ? _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("LoaderCard", {
                    attrs: { flat: "", type: "spinner--center" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-tabs",
        {
          attrs: { "slider-color": "secondary" },
          model: {
            value: _vm.activeOrganization,
            callback: function($$v) {
              _vm.activeOrganization = $$v
            },
            expression: "activeOrganization"
          }
        },
        [
          _vm._l(_vm.organizations, function(organization, index) {
            return [
              _c(
                "v-tab",
                {
                  key: "planning-tab-" + index,
                  staticClass: "tab__filled",
                  class: { active: _vm.activeOrganization === organization.id },
                  attrs: {
                    href: "#" + organization.id,
                    disabled: _vm.isLoading
                  },
                  on: {
                    click: function($event) {
                      return _vm.activeOrganizationChanged(organization.id)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(organization.name) + " ")]
              )
            ]
          }),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.activeOrganization,
                callback: function($$v) {
                  _vm.activeOrganization = $$v
                },
                expression: "activeOrganization"
              }
            },
            [
              _vm._l(_vm.organizations, function(organization) {
                return [
                  _c(
                    "v-tab-item",
                    {
                      key: "output-tc-tab-content-" + organization.id,
                      attrs: {
                        lazy: true,
                        transition: false,
                        "reverse-transition": false,
                        value: organization.id
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "elementPanel",
                          attrs: { row: "", wrap: "" }
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", "justify-end": "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { shrink: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        wrap: "",
                                        "justify-space-between": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "info-icon",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openReadMoreDialog(
                                                                    "output"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "info_outline"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("klik voor meer info")
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", "justify-end": "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass:
                                    "custom-filter--userFilterReports",
                                  attrs: { shrink: "" }
                                },
                                [
                                  _c("UserFilter", {
                                    ref: "userFilter",
                                    refInFor: true,
                                    attrs: {
                                      filterType:
                                        "outgoing-dashboard-" +
                                        _vm.activeOrganization,
                                      filters: _vm.filters
                                    },
                                    on: {
                                      selectedUserFilters:
                                        _vm.onSelectApplyFilter
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", "align-end": "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "dialog",
                                      refInFor: true,
                                      attrs: {
                                        "close-on-content-click": false,
                                        lazy: "",
                                        "nudge-right": "130",
                                        "full-width": "",
                                        "max-width": "290px",
                                        "min-width": "290px"
                                      }
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "input input-date",
                                        attrs: {
                                          slot: "activator",
                                          "hide-details": "",
                                          placeholder: "dd-mm-jjjj",
                                          label: "Datum vanaf"
                                        },
                                        slot: "activator",
                                        model: {
                                          value: _vm.date.formattedDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.date,
                                              "formattedDate",
                                              $$v
                                            )
                                          },
                                          expression: "date.formattedDate"
                                        }
                                      }),
                                      _c("v-date-picker", {
                                        ref: "picker",
                                        refInFor: true,
                                        attrs: {
                                          locale: "nl-nl",
                                          min: "1910-01-01",
                                          color: "#837f16",
                                          "first-day-of-week": "1"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.dateChanged()
                                          }
                                        },
                                        model: {
                                          value: _vm.date.selectedDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.date,
                                              "selectedDate",
                                              $$v
                                            )
                                          },
                                          expression: "date.selectedDate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                                [
                                  _c("v-autocomplete", {
                                    staticClass: "with-border",
                                    attrs: {
                                      items: _vm.statusses,
                                      "item-value": "key",
                                      "item-text": "label",
                                      "hide-details": "",
                                      label: "Status",
                                      clearable: "",
                                      multiple: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend-item",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-list-tile",
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      color: "primary",
                                                      "hide-details": ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "pl-4"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.selectAllStatus
                                                                          ? "(deselecteer alles)"
                                                                          : "(selecteer alles)"
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.selectAllStatus,
                                                      callback: function($$v) {
                                                        _vm.selectAllStatus = $$v
                                                      },
                                                      expression:
                                                        "selectAllStatus"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "selection",
                                          fn: function(ref) {
                                            var index = ref.index
                                            return [
                                              index === 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Status (" +
                                                        _vm._s(
                                                          _vm.filters.statusses
                                                            .length
                                                        ) +
                                                        ")"
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.filters.statusses,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "statusses", $$v)
                                      },
                                      expression: "filters.statusses"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                                [
                                  _c("v-autocomplete", {
                                    staticClass: "with-border",
                                    attrs: {
                                      items: _vm.breakdowns,
                                      "item-value": "value",
                                      "item-text": "name",
                                      "hide-details": "",
                                      label: "Breakdown"
                                    },
                                    model: {
                                      value: _vm.filters.breakdown,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "breakdown", $$v)
                                      },
                                      expression: "filters.breakdown"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                                [
                                  _c("ReportTypeFilter", {
                                    attrs: {
                                      label: "Rapporttype",
                                      placeholder: "",
                                      cssClass: "with-border",
                                      canSelectAll: true
                                    },
                                    model: {
                                      value: _vm.filters.types,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "types", $$v)
                                      },
                                      expression: "filters.types"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                                [
                                  _c("ReportDepartmentFilter", {
                                    attrs: {
                                      label: "Afdelingen",
                                      placeholder: "",
                                      cssClass: "with-border",
                                      dmz: _vm.activeOrganization,
                                      canSelectAll: true
                                    },
                                    model: {
                                      value: _vm.filters.departments,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "departments",
                                          $$v
                                        )
                                      },
                                      expression: "filters.departments"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                                [
                                  _c("WorkFlowFilter", {
                                    attrs: {
                                      label: "Workflow",
                                      placeholder: "",
                                      cssClass: "with-border",
                                      canSelectAll: true
                                    },
                                    model: {
                                      value: _vm.filters.workflows,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "workflows", $$v)
                                      },
                                      expression: "filters.workflows"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                                [
                                  _c("OpnameVariantFilter", {
                                    attrs: {
                                      label: "Opname variant",
                                      placeholder: "",
                                      cssClass: "with-border",
                                      canSelectAll: true
                                    },
                                    model: {
                                      value: _vm.filters.opname_variants,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "opname_variants",
                                          $$v
                                        )
                                      },
                                      expression: "filters.opname_variants"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "mb-2": "" } },
                                [
                                  _c("ManagementClassFilter", {
                                    attrs: {
                                      label: "Rapportage classificatie",
                                      placeholder: "",
                                      cssClass: "with-border",
                                      canSelectAll: true
                                    },
                                    model: {
                                      value:
                                        _vm.filters.management_info_classes,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "management_info_classes",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filters.management_info_classes"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.$store.state.Auth &&
                              _vm.$store.state.Auth.has_management_info
                                ? _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm3: "", "mb-2": "" }
                                    },
                                    [
                                      _c("ReportExpertFilter", {
                                        attrs: {
                                          dmz: _vm.activeOrganization,
                                          cssClass: "with-border",
                                          placeholder: "Medewerkers"
                                        },
                                        model: {
                                          value: _vm.filters.experts,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "experts",
                                              $$v
                                            )
                                          },
                                          expression: "filters.experts"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-xs-right",
                                  attrs: { sm12: "" }
                                },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        color: "primary",
                                        small: "true",
                                        outline: "true"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.applyFilters()
                                        }
                                      }
                                    },
                                    [_vm._v(" Pas filters toe ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", {
                                staticClass: "separator",
                                attrs: { xs12: "" }
                              })
                            ],
                            1
                          ),
                          _vm.isLoading
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("LoaderCard", {
                                        attrs: {
                                          flat: "",
                                          type: "spinner--center"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isLoading
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "overflow-auto" },
                                  [
                                    _vm.users[_vm.activeOrganization] &&
                                    _vm.users[_vm.activeOrganization].length
                                      ? _c("WeekStatisticsView", {
                                          attrs: {
                                            items:
                                              _vm.users[_vm.activeOrganization],
                                            columns: _vm.columns,
                                            options: _vm.statisticsOptions
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                !_vm.users[_vm.activeOrganization] ||
                                !_vm.users[_vm.activeOrganization].length
                                  ? _c("div", { staticClass: "mt-3" }, [
                                      _c("span", [
                                        _vm._v(
                                          'Druk op de knop "Pas filters toe" om de resultaten te bekijken.'
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  )
                ]
              })
            ],
            2
          )
        ],
        2
      ),
      _vm.isReadingMoreInfo
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isReadingMoreInfo,
                callback: function($$v) {
                  _vm.isReadingMoreInfo = $$v
                },
                expression: "isReadingMoreInfo"
              }
            },
            [
              _c("MoreInfoDialog", {
                attrs: {
                  title: _vm.activeMoreInfo.title,
                  description: _vm.activeMoreInfo.description
                },
                model: {
                  value: _vm.isReadingMoreInfo,
                  callback: function($$v) {
                    _vm.isReadingMoreInfo = $$v
                  },
                  expression: "isReadingMoreInfo"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }